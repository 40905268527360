<template>
<section class="px-3 pb-1">
 
  <div class="pt-6">
    <h3></h3>
    <p class="text-left" style="font-size:.9rem">
      <ol class="pl-4">
        <li>Program SERBA HOKI disediakan untuk Seluruh Pelanggan setia TELKOMSEL</li>
        <li>Program SERBA HOKI diawasi dan diputuskan oleh Juri yang beranggotakan Pihak Penyelenggara</li>
        <li>Pelanggan yang terdaftar dan aktif dilayanan/ keyword SERBA HOKI  akan mendapatkan kesempatan untuk bermain game di Portal Serba Hoki dan memenangkan rewardnya</li>
        <li>Pelanggan yang mencapai skor tertinggi di akhir periode bisa Berkesempatan mendapatkan Hadiah Pesta Reward 9.</li>
        <li>Untuk mendapatkan reward Langsung berupa PAKET DATA 1GB pelanggan harus bisa mendapatkan skor tertinggi setiap minggu nya.</li>
        <li>Para Pemenang akan dihubungi langsung oleh pihak Customer Service Ulatbulu untuk verifikasi data</li>
        <li>Penukaran SKOR bersifat WAJIB untuk Peserta/ Pemenang Reward UTAMA</li>
        <li>Peserta yang ikut dilayanan SERBA HOKI akan dikenakan biaya mulai dari Rp1110/1hr, Rp.2220/2hr, Rp.3330/3hr, Rp.5550/7hr slm 360 hari</li>
        <li>Pelanggan yang sukses terpotong biaya, maka akan mendapatkan 1 token dan 10 Skor</li>
        <li>Pelanggan yang menang dan akan meredeem SKORnya wajib mengirimkan Data-data administrasi antara lain:
          <ul class="pl-3">
            <li>Fotocopy kartu identitas diri yang masih berlaku (KTP/SIM/KartuPelajar)</li>
            <li>Nomor MSISDN/HP sesuai dengan yang telah didaftar dalam database Penyelenggara dan masih aktif mengikuti/ terdaftar dilayanan SERBA HOKI.</li>
            <li>Data-data administrasi dapat dikirim via email ke alamat <a href="mailto:cs@ulatbulu.id">cs@ulatbulu.id</a></li>
          </ul>
        </li>
        <li>Jika dalam jangka waktu 1 (satu) minggu setelah tanggal pengumuman Pelanggan dengan SKOR tertinggi dan tercepat tidak mengirimkan data-data kelengkapan administrasi yang diminta oleh Penyelenggara, maka reward dapat dibatalkan</li>
        <li>Ini BUKAN PROGRAM UNDIAN</li>
        <li>Tidak mengandung unsur layanan untung-untungan</li>
        <li>Ketentuan atas layanan ini tidak bersifat final, segala perubahan terhadap syarat & ketentuan adalah Hak Juri</li>
        <li>Reward sewaktu-waktu dapat berubah tanpa ada pemberitahuan sebelumnya. Jika reward tidak tersedia, misal stok produk dipasaran habis dan atau sudah discontinue, maka Pihak Penyelenggara berhak untuk menggantikan dengan reward lainnya yang sejenis dana tau senilai dengan reward yang seharusnya diterima oleh Pemenang. Atau menggantikannya dengan Uang Tunai, namun hal ini merupakan opsi dari Pihak Penyelenggara dan BUKAN pilihan dari Pemenang atau Peserta</li>
        <li>Info lebih lanjut bisa menghubungi Customer Service kami di: <b>021-22732014</b></li>
      </ol>
      </p>
      <p class="text-left" style="font-size:.9rem">
        <b class="mb-3 d-block">Mekanisme program reward</b>
        <ol class="pl-4">
          <li>Saat pelanggan menyetujui untuk mengikuti Program SERBA HOKI maka pelanggan akan dikenakan biaya Rp.1110/sms/1hari, Rp.2220/sms/2hr, Rp.3330/sms/3hr, Rp.5550/sms/7hr slm 360 hari (incl PPn) dari SDC <b>94449</b> </li>
          <li>Sms berlangganan akan dikirimkan ke pelanggan dengan dikenakan harga sebesar Rp.1110/sms/1hari, Rp.2220/sms/2hr, Rp.3330/sms/3hr, Rp.5550/sms/7hr (incl PPn) selama 360 hari</li>
          <li>Pelanggan akan menerima 1 token dan 10 skor setiap hari selama berlangganan </li>
          <li>Untuk mendapatkan reward Langsung,dengan mengumpulkan skor akumulasi langsung didalam permainan 
            <ul class="pl-3">
              <li>Pelanggan yang mencapai Skor Tertinggi dan tercepat setiap minggu akan mendapatkan reward 1 GB data </li>
            </ul>
            <b-alert show variant="dark" class="mt-3 text-justify">
              <p class="font-weight-bolder">Note:</p>
              <ul class="pl-3">
                <li>Msisdn/ Nomor HP masih aktif berlangganan dilayanan SERBA HOKI KTP/ identitas diri masih berlaku</li>
              </ul>
            </b-alert>
          </li>
          <li>Pelanggan tidak berhasil mendapatkan reward langsung , maka skornya diakumulasi ke portal game yang bisa mendapatkan reward Utama jika memiliki skor paling tertinggi selama periode nya berlangsung</li>
          <li>Untuk berhenti dari layanan sms berlangganan ini, Pelanggan ketik <b>STOP</b> atau <b>UNREG PRTL1/PRTL2/PRTL3/PRTL5</b> ke <b>94449</b>
          </li>
        </ol>
      </p>
  </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex' 
export default {
  name: 'Tnc',
  components: {
  },
  data() {
    return {
      titleHeader: "Syarat & Ketentuan",
      showToken: false,
      showBg: true,
    };
  },
  // mounted() {
  //   console.log(this.store, this.coin);
  // },
  computed: {
    ...mapState({
      coin: 'coin',
    })
  },
  methods: {
    goTo(page) {
        this.$router.push({ name: page });
    },
  }
}
</script>

<style>
body {
    background-color: white;
}
</style>
<style scoped>
.actives {
  background-color: #fff !important;
  font-weight: 600;
}
.bg-burem {
  background-color: #EFF0F6 !important;
}
.text-burem a{
  color: #A0A3BD !important;
}
.text-hitam a{
  color: #212121 !important;
}
</style>
