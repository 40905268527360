<template>
  <b-container fluid class="h-100 px-0" style="min-height:100vh;">
    <div class="content-login h-100" style="min-height:100vh;">
      <b-row
        cols-lg="3"
        cols-md="2"
        cols-sm="2"
        class="justify-content-center h-100 mx-0"
      >
        <b-col align-self="center">
          <b-card class="card-login mt-5">
            <b-img
              style="height:10rem;"
              class="mb-4"
              alt="Vue logo"
              :src="require('@/assets/login-logo.png')"
            ></b-img>
            <h4 class="txt-white mt-4">Hello!</h4>
            <span class="txt-white"
              >Untuk mulai main, masukkan nomor <br />
              handphonemu disini:</span
            >
            <b-form @submit.prevent="handleSubmit" class="max-35 px-1">
              <b-form-input
                id="form-input-name"
                @focus="hideAlert()" 
                class="my-3 max-35 text-center rounded-pill form-control-lg"
                placeholder="62812xxxxxxx"
                v-model="msisdn"
                required
              ></b-form-input>
              <b-button
                type="submit"
                block
                variant="primary"
                class="max-35 rounded-pill btn-lg"
                >Login</b-button
              >
            </b-form>
            <b-tooltip ref="tooltip" target="form-input-name">
              <div class="tooltip-custom">Masukan no <strong>Telkomsel</strong> anda.</div>
            </b-tooltip>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
// import { accessApi, apiPortal, responseApi } from '@/provider'
// import { toEncrypt } from "../provider";
export default {
  data() {
    return {
      msisdn: null,
      token: null,
      form: {
        msisdn: null
      },
      telcos: null,
      isLoading: false,
    };
  },
  mounted() {
    this.hideAlert()
  },
  computed: {
    ...mapState([
      'telco',
      'secretKey'
    ])
  },
  methods: {
    ...mapActions([
      'getProfile',
    ]),
    async handleSubmit() {
      let msisdn = this.msisdn;

      if (msisdn.charAt(0) === "0") {
        msisdn = "62" + msisdn.substring(1);
      }
      this.msisdn = msisdn;
      this.$store.state.isLoading = true
      const _token = btoa(msisdn);
      this.$store.state.user = msisdn;
      this.token = _token;
      this.getProfile().then(() => {
        this.$store.state.isLoading = false
        if (this.telco === 'tsel') {
          this.setStore();
          this.$router.push({ name: "loading" });
        } else {
          this.showAlert()
        }
      }, error => {
        console.error('err', error);
      })
      
      // this.getData(msisdn)
    },
    setStore() {
      this.$store.commit("SET_TOKEN", this.token);
      this.$store.commit("SET_USER", this.msisdn);
    },
    // async getData() {
    //   const self = this
    //    accessApi().then(async (token) => {
    //     const msisdn = toEncrypt(self.msisdn, this.secretKey) 
    //     const sendData = { req: msisdn }
    //     const headers = { Authorization : `bearer ${token}`}
    //     const { data } = await apiPortal.post('gameProfile', sendData, { headers })
    //     const dataProfile = await JSON.parse(responseApi(data))
    //     const JsonDataProfile = JSON.parse(dataProfile)
    //     const telcos = JsonDataProfile.telco.mno_shortname
    //     if (telcos === 'tsel') {
    //       this.isLoading = true
    //       this.$router.push({ name: "loading" });
    //     } else {
    //       this.showAlert()
    //     }
    //     console.log(telcos);
    //     // setTimeout(this.hideAlert(), 300000)
    //   })
    // },
    showAlert() {
      // this.dismissCountDown = this.dismissSecs
      this.$refs.tooltip.$emit('enable')
      this.$refs.tooltip.$emit('open')
    },
    hideAlert() {
      // this.dismissCountDown = this.dismissSecs
      this.$refs.tooltip.$emit('disable')
      this.$refs.tooltip.$emit('close')
    }
  }
};
</script>

<style scoped>
.content-login {
  /* background: url("../assets/bg-layout.png") bottom no-repeat,
    linear-gradient(167.34deg, #ff4201 25.68%, #ff0077 65.68%);
  background-size: contain; */
  background-color: #D82827;
;
}
.card-login {
  background-color: transparent;
  border: none;
  transform: translate(0%, 9%);
  -webkit-transform: translate(0%, 9%);
}
.txt-white {
  color: white;
}
.tooltip-custom {
    /* color: red !important; */
    font-size: .8rem !important;
    max-width: 300px !important;
    padding: .5rem !important;
}
</style>
