<template>
  <section>
    <Header :title="titleHeader"  :token="showToken" :background="showBg" :coin="coin" />
    <!-- <Loader :isLoading="isLoading" /> -->
    <!-- <div v-if="!isLoading"> -->
     <Syarat/>
    <!-- </div> -->
  </section>
</template>
<script>
// @ is an alias to /src
import { mapState } from 'vuex' 
import Header from '@/components/Header.vue'
import Syarat from '@/components/LayoutSyarat.vue'
export default {
  name: 'Tnc',
  components: {
    Header,
    Syarat,
  },
  data() {
    return {
      titleHeader: "Syarat & Ketentuan",
      showToken: false,
      showBg: true,
    };
  },
  // mounted() {
  //   console.log(this.store, this.coin);
  // },
  computed: {
    ...mapState({
      coin: 'coin',
    })
  },
  methods: {
    goTo(page) {
        this.$router.push({ name: page });
    },
  }
}
</script>

<style>
body {
    background-color: white;
}
</style>
<style scoped>
.actives {
  background-color: #fff !important;
  font-weight: 600;
}
.bg-burem {
  background-color: #EFF0F6 !important;
}
.text-burem a{
  color: #A0A3BD !important;
}
.text-hitam a{
  color: #212121 !important;
}
</style>
