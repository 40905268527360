<template>
  <section>
    <!-- <Loader :isLoading="isLoading" /> -->
    <!-- <div v-if="!isLoading"> -->
      <div>
      <b-card no-body class="comp">
        <b-tabs card class="compx">
          <b-tab title="FAQ" active @click="goTab">
            <b-card-text>
              <FAQ/>
            </b-card-text>
          </b-tab>
          <b-tab title="Syarat & Ketentuan" @click="goTab">
            <b-card-text>
              <Syarat/>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
     
    <!-- </div> -->
  </section>
</template>

<script>
// @ is an alias to /src
import Syarat from "@/components/LayoutSyarat.vue"
import FAQ from "@/components/LayoutFaq.vue"
import router from '@/router'
// import Loader from '@/components/Loader.vue'
export default {
  name: "Home",
  components: {
    Syarat,
    FAQ,
    // Loader,
  },
  data() {
    return {
      swiperOptions: {
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      // isLoading: true,
      },

      dataLeaderboard: [],
      dataProfile: null,
    };
  },
  mounted() {
    
  },
 
  methods: {
    handleSubmit() {},
    goTo(page) {
      router.push({ name: page });
    },
    goTab(){
      console.log('here')
    },
  }
};
</script>
<style>
.compx .nav-tabs .nav-link{
  color: #fff;
}
.compx .nav-tabs .nav-link.active{
  color: #d82827;
}
.compx>.card-header{
  background-color: #d82827  !important;
  border: none;
}
body {
  background-color: white;
}
.header-home {
  height: 75px;
  /* background: linear-gradient(90deg, #ff4401 0%, #ff0077 57.29%); */
  /* background-color: #fff; */
  /* background-color: #f7f7fc; */
  background: linear-gradient(92.88deg, #F11240 4.2%, #BF0244 97.09%);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
}
.home {
  padding-top: 6rem;
}

.media-aside-right {
  margin-right: 0;
  margin-left: 0.5rem !important;
}
</style>
