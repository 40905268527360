<template>
  <section class="px-3 pb-1">
    <Header
      :title="titleHeader"
      :background="showBg"
    />
    <FAQ/>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import Header from "@/components/Header.vue";
import FAQ from "@/components/LayoutFaq.vue";
export default {
  name: "Faq",
  components: {
    Header,
    FAQ,
  },
  data() {
    return {
      titleHeader: "FAQ",
      showToken: false,
      showBg: true,
      isActive: false,
    };
  },
  // mounted() {
  //   console.log(this.store, this.coin);
  // },
  computed: {
    ...mapState({
      coin: "coin",
    }),
  },
  methods: {
    goTo(page) {
      this.$router.push({ name: page });
    },
  },
};
</script>

<style>
body {
  background-color: white;
}
</style>
<style scoped>
.actives {
  background-color: #fff !important;
  font-weight: 600;
}
.bg-burem {
  background-color: #eff0f6 !important;
}
.text-burem a {
  color: #a0a3bd !important;
}
.text-hitam a {
  color: #212121 !important;
}
.btn-custom {
    color: #212121;
    background-color: #fff;
    border: none;
    outline: none;
    text-align: left;
}
.btn-custom:focus, .btn-custom.focus, .btn-custom.not-collapsed {
    color: #D82827;
    border: none;
    box-shadow: none;
}
.btn-custom:not(:disabled):not(.disabled):active, .btn-custom:not(:disabled):not(.disabled).active, .show > .btn-custom.dropdown-toggle {
    color: #D82827;
    border: none;
    background-color: transparent;
}
.btn-custom:active, .btn-custom.active {
 background-color: red;
}
.card {
  border-radius: 12px;
}
.card-header, .card {
  border: none;
  background-color: #fff;
}
.card-body {
  background-color: #F6F6F6;
}
.accordion {
  background: #FFFFFF;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
/deep/ .table th {
    padding: 0.4rem;
    vertical-align: top;
    border: 1px solid #dee2e6;
}
/deep/ .table td {
    padding: 0.4rem;
    vertical-align: top;
    border: 1px solid #dee2e6;
}
/deep/ .f-xs {
  font-size: x-small;
}
</style>
