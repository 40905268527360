<template>
  <section class="px-3 pb-1">
    <div class="pt-6 text-left custom-div" style="font-size: 0.9rem">
      <!-- <h6>CARA MENDAPATKAN SKOR</h6> -->
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1" v-for="each in faq" :key="each.id">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="'accordion-' + each.id" class="btn-custom">
              <b>{{each.title}}</b>
              <b-icon icon="plus" class="float-right when-closed" font-scale="1"></b-icon>
              <b-icon icon="dash" class="float-right when-open" font-scale="1"></b-icon>
            </b-button>
          </b-card-header>
          <b-collapse
            @hide="isActive = false"
            @show="isActive = true"
            v-bind:id="'accordion-' + each.id"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text v-html="each.description" class="text-justify">
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
export default {
  name: "Faq",
  components: {
  },
  data() {
    return {
      titleHeader: "FAQ",
      showToken: false,
      showBg: true,
      isActive: false,
      faq: [
        {
          id: 1,
          title: "Apa itu SERBA HOKI?",
          description: `
          SERBA HOKI adalah Portal Game yang memuat semua game UBmobile ada dalam satu portal berbasis aplikasi / HTML5 , dengan reward langsung seperti Gopay dan pulsa. Pelanggan bisa raih kesempatan untuk mendapatkan banyak reward dengan cara mengumpulkan SKOR di dalam Game . Disemua game ada Skor reward yang bisa didapat, jika pelanggan mengenai Icon bintang di area permainan. Ada juga reward langsung yang bisa didapat jika pelanggan berhasil mencapai skor tertentu.
          `,
        },
        {
          id: 2,
          title: "Bagaimana cara ikutan?",
          description: `
          <ol>
            <li>Pelanggan mengikuti layanan melalui akses SMS dengan mengetik: <b>REG PRTL1</b>/<b>PRTL2</b>/<b>PRTL3</b>/<b>PRTL5</b> ke <b>94449</b> </li>
            <li>Pelanggan akan mendapatkan sms konfirmasi untuk mengikuti layanan dengan ketik <b>YA</b></li>
            <li>Kemudian pelanggan menerima sms terima kasih dan sms konten berisi url portal game</li>
            <li>Pelanggan akan dikenakan tariff Rp1110/sms/hari, Rp.2220/sms/2 hari, Rp.3330/sms/3 hari, Rp.5550/sms/7 hari (incl PPn) dan akan mendapatkan 1 token setiap hari  dan 10 skor selama masa sukses berlangganan.</li>
            <li>Pelanggan bisa membeli token tambahan dengan ketik sms <b>TOKEN Rp.1110</b> ke <b>94449</b> (incl PPn) , pelanggan mendapatkan 1 token setiap pembelian PULL konten.</li>
            <li>Pelanggan bisa cek skor dengan ketik <b>CEK</b> ke <b>94449</b> </li>
            <li>Untuk berhenti berlangganan dengan mengetik <b>UNREG PRTL1</b>/<b>PRTL2</b>/<b>PRTL3</b>/<b>PRTL5</b> kirim ke <b>94449</b></li>
          </ol>
          `,
        },
        {
          id: 3,
          title: "Apa saja permainan dalam SERBA HOKI?",
          description: `
            <ul class="pl-3">
              <li>Balap Hoki , Permainan balap mobil berbasis HTML5 . Balap hoki ini menyediakan banyak trek . Bisa mendapatkan reward langsung</li>
              <li>Bola hoki , Permainan tendangan pinalty berbasis HTML5 . Dipermainan ini penendang mendapatkan 30x kesempatan.</li>
              <li>Ular Tenggo , Permainan ini seperti ular tangga pada umum nya , tetapi game ini dibikin sangat menarik dan menantang , permainan ini dibuatkan 3 sesi dengan durasi dan papan permainan yg berbeda beda. berkesempatan mendapatkan reward langsung.</li>
              <li>Ketokoo adalah game Supermarket Modern menjual semua produk kebutuhan sehari-hari dari mulai sayuran, frozen food, Fresh Meat, dan juga kebutuhan modern lainnya seperti Komputer, Handphone, Kartu Perdana, Sepeda dan masih banyak lainnya.Konsep Supermarket Modern sengaja dibuat agar Pelanggan bisa memenuhi semua kebutuhannya di satu tempat.</li>
            </ul>
          `,
        },
        {
          id: 4,
          title: "Bagaimana cara bermain",
          description: `
            <h6>BALAP HOKI</h6>
            <ul class="pl-3">
              <li>Permainan balap mobil yang didalam trek nya ada tantangan Pemain bisa mengumpulkan banyak skor jika menabrak icon bintang didalam trek.</li>
              <li>1 token bisa dimainkan 3 trek untuk game balap hoki</li>
              <li>Pemain bisa mendapatkan reward langsung dari game balap hoki , jika mencapai skor yang di tentukan.</li>
              <li>Game ini terdapat  trek yang berbeda beda.</li>
              <li>Setiap trek dimainkan dengan durasi yang berbeda beda dengan tingkat level nya</li>
            </ul>
            <h6>BOLA HOKI</h6>
            <ul class="pl-3">
              <li>Bola hoki adalah permainan adu penalty ,pemain menjadi penendang</li>
              <li>Pemain bisa mengumpulkan banyak skor jika penendang mengenai icon bintang di area gawang.</li>
              <li>1 token, pemain mendapatkan 30x tendangan.</li>
              <li>Pemain bisa mendapatkan reward langsung dari game bola hoki , jika mencapain skor yang ditentukan.</li>
              <li>Di permainan ini cuma terdapat satu lapangan saja.</li>
            </ul>
            <h6>ULAR TENGGO</h6>
            <ul class="pl-3">
              <li>Permainan seperti ular tangga , namun dibuat dengan user experience yang berbeda didalam game terdapat reward menarik yang dimenangkan.</li>
              <li>Pemain mengumpulkan skor dengan cara menginjak icon bintang pada papan permainan.</li>
              <li>Jika pemain menginjak bom yang ada dipapan permainan , pemain akan mengulang lagi dari awal.</li>
              <li>Jika pemain menginjak ular , pemain akan turun  , jika pemain menginjak tangga , pemain akan naik.</li>
              <li>Tematik pada papan permainan.</li>
              <li>1 Token bisa dimainkan 3 sesi dan setiap sesi berdurasi 2 menit.</li>
              <li>Untuk mencapai finish , jika angka dadu tidak sesuai dengan kotak jalan , maka tidak bergerak dan dilanjutkan kocok dadu untuk lawan.</li>
            </ul>
            <h6>KETOKOO</h6>
            <ul class="pl-3">
              <li>Permainan tap market , dimana pemain menjadi kasir dan mejaga stok barang yang ada ditoko</li>
              <li>Permainan yang mengatur pembeli supaya tidak terjadi antrian Panjang saat melakukan pembayaran di kasir.</li>
              <li>Durasi permainan 3 menit</li>
              <li>10 pelanggan sukses melakukan pembayaran dikasir , akan mendapatkan 1skor.</li>
              <li>Dibatasi waktu saat melakukan pembayaran dikasir supaya pelanggan tidak kecewa.</li>
              <li>Pemain bisa mendapatkan reward langsung , jika skor yang di kumpulkan sesuai dengan ketentuan yang berlaku.</li>
            </ul>
          `,
        },
        {
          id: 5,
          title: "Bagaimana cara mendapatkan reward?",
          description: `
          <ol>
            <li>Program Pesta Reward disediakan untuk seluruh pelanggan TELKOMSEL</li>
            <li>Periode Program berlaku selama 1 tahun dan diperpanjang secara otomatis</li>
            <li>Dengan mengikuti layanan Pesta Reward, Pelanggan menyetujui seluruh ketentuan terkait produk, harga, masa berlangganan, cara berhenti berlangganan, Customer Service, dll yang telah diinformasikan pada saat promosi</li>
            <li>Produk yang diterima oleh Pelanggan yaitu berupa Aplikasi, Games, Video, Musik, dll</li>
            <li>Pelanggan dapat mengikuti layanan Pesta Reward melalui SDC 94449</li>
            <li>Program Pesta Reward diawasi dan diputuskan oleh Juri yang beranggotakan Pihak Penyelenggara</li>
            <li>Keputusan juri dan penyelenggara tidak dapat diganggu gugat</li>
            <li>Program Pesta Reward ini merupakan program yang berbentuk kompetisi dengan cara mengumpulkan skor.</li>
            <li>Pemenang Pesta Reward adalah Pelanggan loyal yang masih dan harus terdaftar / aktif dalam layanan Serbahoki</li>
            <li>Dalam proses tahap pembagian dan pengiriman reward untuk dipastikan agar Pemenang tetap aktif berlangganan dilayanan hingga proses serah terima selesai sepenuhnya.</li>
            <li>Jika dalam proses tahap pembagian, pengiriman dan serah terima reward Pemenang tidak aktif berlangganan dilayanan, maka dinyatakan gugur/ di diskualifikasi dari layanan Pesta Reward.</li>
            <li>Pelanggan yang terdaftar dan aktif dilayanan akan mendapatkan skor yang dapat ditukarkan dengan reward menarik</li>
            <li>Yang bisa atau berhak menukarkan skornya adalah Pelanggan yang memiliki Skor Tertinggi dan Tercepat disetiap periodenya, berdasarkan catatan log di system kami.</li>
            <li>Pelanggan hanya berhak menukarkan rewardnya 1x disetiap periodenya, dan tidak bisa mendapatkan reward yang sama secara berulang diperiode yang sama. </li>
            <li>Pelanggan hanya bisa mendapatkan reward yang berbeda di periode yang berbeda. Contoh penjelasan: Misal Pelanggan “A” sudah pernah menerima reward di periode BULAN ke 1, maka Ia tidak berhak lagi menerima reward di periode bulan yang sama, namun Pelanggan “A” berhak mendapatkan reward kembali di periode berikutnya misalnya di periode BULAN ke 2 dst.</li>
            <li>Pemenang reward hanya berlaku untuk 1 msisdn atau 1 nomor handphone dan 1 kartu identitas dengan Nama yang sama.</li>
            <li>Para Pemenang akan dihubungi langsung oleh pihak Customer Service Pesta Reward untuk verifikasi data para pemenang Pesta Reward.</li>
            <li>Penukaran skor dengan reward bersifat WAJIB untuk Peserta/ Pemenang.</li>
            <li>Peserta yang ikut dilayanan Pesta Reward akan dikenakan biaya mulai dari Rp1110rb/pesan,Rp.2220/pesan,Rp.3330/pesan,Rp.5550/pesan</li>
            <li>Terdapat Skor yang akan diberikan kepada Pelanggan yang sukses charged, 1 sms yang sukses bernilai 10 skor. Jika tidak sukses charge maka skornya 0.</li>
            <li>Penghitungan Skor akan kembali 0 (nol) atau posisi awal jika Skor sudah ditukarkan dengan reward tertentu dan jika periode program Pesta Reward 9 sudah berakhir yaitu pada 31 Januari 2025 pukul 23.59 WIB</li>
            <li>Pelanggan yang menang dan akan meredeem skornya wajib mengirimkan Data-data administrasi antara lain:
                <ul>
                    <li>Fotocopy kartu identitas diri yang masih berlaku (KTP/SIM/KartuPelajar juga Kartu Keluarga)</li>
                    <li>Nomor MSISDN/HP sesuai dengan yang telah didaftar dalam database Penyelenggara dan masih aktif mengikuti/ terdaftar dilayanan.</li>
                </ul>
            </li>
            <li>Alamat pengiriman reward harus sesuai dengan alamat di KTP.</li>
            <li>Data-data administrasi dapat dikirim via email ke alamat <a href="mailto:cs@pestareward.id">cs@pestareward.id</a>, atau WA di 08111717224</li>
            <li>Pengiriman Reward akan di kirimkan maksimal 1 (satu) minggu jika data administrasi sudah lengkap.</li>
            <li>Jika dalam jangka waktu 1 (satu) minggu setelah tanggal pengumuman Pelanggan dengan Skor tertinggi dan tercepat tidak mengirimkan data-data kelengkapan administrasi yang diminta oleh Penyelenggara, maka reward dapat diberikan ke Pelanggan lain yang memenuhi kriteria.</li>
            <li>Ini BUKAN PROGRAM UNDIAN.</li>
            <li>Total reward yang diberikan kepada pelanggan setia Pesta Reward untuk program layanan ini tidak terbatas selama periode waktu yang sudah di tentukan oleh pihak penyelenggara</li>
            <li>Tidak mengandung unsur layanan untung-untungan.</li>
            <li>Ketentuan atas layanan ini tidak bersifat final, segala perubahan terhadap syarat & ketentuan adalah Hak Juri.</li>
            <li>Reward sewaktu-waktu dapat berubah tanpa ada pemberitahuan sebelumnya. Jika reward tidak tersedia, misal stok produk dipasaran habis dan atau sudah discontinue, maka Pihak Penyelenggara berhak untuk menggantkan dengan reward lainnya yang sejenis dan atau senilai dengan reward yang seharusnya diterima oleh Pemenang. Atau menggantikannya dengan Uang Tunai, namun hal ini merupakan opsi dari Pihak Penyelenggara dan BUKAN pilihan dari Pemenang atau Peserta.</li>
            <li>Untuk berhenti dari program pesta reward ini, Pelanggan dapat melakukan misal melalui sms ketik <b>UNREG PRTL1</b>/<b>PRTL2</b>/<b>PRTL3</b>/<b>PRTL5</b> kirim ke SDC <b>94449</b></li>
            <li>Ketika Pelanggan dalam kondisi tidak memiliki Pulsa atau pulsa tidak mencukupi :
                <ul>
                    <li>Pelanggan tetap dalam status berlangganan layanan.</li>
                    <li>Pelanggan tidak mendapatkan konten atau layanan.</li>
                    <li>Saat Pelanggan telah memiliki Pulsa kembali, maka secara otomatis Pelanggan berhak mendapatkan konten atau layanannya kembali.</li>
                    <li> Status Pelanggan masih akan tetap berlangganan selama Pelanggan belum melakukan pemberhentian berlangganan layanan atau unreg.</li>
                </ul>
            </li>
        </ol>
          `,
        },
        {
          id: 6,
          title: "List Reward",
          description: `
          <table class="table f-xs">
              <tr>
                <th class="text-left">No</th>
                <th class="text-left">Reward</th>
                <th class="text-center">QTY</th>
              </tr>
              <tr>
                <td class="text-left">1.</td>
                <td>Uang Tunai Rp 1.000.000</td>
                <td class="text-center">40</td>
              </tr>
              <tr>
                <td class="text-left">2.</td>
                <td>Logam Mulia 10 Gram</td>
                <td class="text-center">2</td>
              </tr>
              <tr>
                <td class="text-left">3.</td>
                <td>Samsung Galaxy Z Flip 5</td>
                <td class="text-center">1</td>
              </tr>
              <tr>
                <td class="text-left">4.</td>
                <td>Apple Watch Ultra</td>
                <td class="text-center">2</td>
              </tr>
              <tr>
                <td class="text-left">5.</td>
                <td>Iphone 15 Pro Max (256GB)</td>
                <td class="text-center">2</td>
              </tr>
              <tr>
                <td class="text-left">6.</td>
                <td>Samsung Galaxy Z Fold 5 (256GB)</td>
                <td class="text-center">2</td>
              </tr>
              <tr>
                <td class="text-left">7.</td>
                <td>Motor Listrik Alva One</td>
                <td class="text-center">1</td>
              </tr>
              <tr>
                <td class="text-left">8.</td>
                <td>Gaji Setahun Rp 12.000.000</td>
                <td class="text-center">1</td>
              </tr>
              <tr>
                <td class="text-left">9.</td>
                <td>Yamaha Grand Filano</td>
                <td class="text-center">1</td>
              </tr>
            </table>
          `,
        },
        {
          id: 7,
          title: "Kapan saja Reward-reward ini dibagikan?",
          description: `
          <p>Berikut untuk jadwal pembagian reward-reward di Serba Hoki</p>
          <table class="table f-xs table-responsive">
              <tr>
                <th class="text-right">No</th>
                <th class="text-right">Reward</th>
                <th class="text-center">Feb-24</th>
                <th class="text-center">Mar-24</th>
                <th class="text-center">Apr-24</th>
                <th class="text-center">May-24</th>
                <th class="text-center">Jun-24</th>
                <th class="text-center">Jul-24</th>
                <th class="text-center">Aug-24</th>
                <th class="text-center">Sep-24</th>
                <th class="text-center">Oct-24</th>
                <th class="text-center">Nov-24</th>
                <th class="text-center">Dec-24</th>
                <th class="text-center">Jan-25</th>
              </tr>
              <tr>
                <td>1.</td>
                <td>Uang Tunai Rp 1.000.000</td>
                <td class="text-center">3</td>
                <td class="text-center">3</td>
                <td class="text-center">4</td>
                <td class="text-center">3</td>
                <td class="text-center">3</td>
                <td class="text-center">4</td>
                <td class="text-center">3</td>
                <td class="text-center">4</td>
                <td class="text-center">3</td>
                <td class="text-center">3</td>
                <td class="text-center">4</td>
                <td class="text-center">3</td>
              </tr>
              <tr>
                <td>2.</td>
                <td>Logam Mulia 10 Gram</td>
                <td class="text-center">1</td>
                <td></td>
                <td class="text-center">1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>3.</td>
                <td>Samsung Galaxy Z Flip 5</td>
                <td class="text-center">1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>4.</td>
                <td>Apple Watch Ultra</td>
                <td></td>
                <td class="text-center">1</td>
                <td></td>
                <td></td>
                <td class="text-center">1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>5.</td>
                <td>Iphone 15 Pro Max (256GB)</td>
                <td></td>
                <td class="text-center">1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-center">1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>6.</td>
                <td>Samsung Galaxy Z Fold 5 (256GB)</td>
                <td></td>
                <td></td>
                <td class="text-center">1</td>
                <td></td>
                <td class="text-center">1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>7.</td>
                <td>Motor Listrik Alva One</td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-center">1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>8.</td>
                <td>Gaji Setahun Rp 12.000.000</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-center">1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>9.</td>
                <td>Yamaha Grand Filano</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td  class="text-center">1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
          </table>
          `,
        },
        {
          id: 8,
          title: "Apa itu Token dan Skor?",
          description: `
            <h6>Cara Mendapatkan Token:</h6>
            <ul class="pl-3">
              <li>Mendapatkan gratis 1 token setiap hari jika sudah berlangganan dan sukses charging.</li>
              <li>Jumlah token akan masuk ke portal game dan tidak hangus selama masa berlangganan</li>
              <li>Masa berlaku token selama berlangganan</li>
              <li>Bisa melakukan pembelian token tambahan apabila token habis</li>
              <li>Main dengan token bisa mengumpulkan skor reward</li>
              <li>Pelanggan bisa main tanpa menggunakan token tetapi tidak mendapatkan skor</li>
              <li>Token bisa dimainkan kesemua game yang ada di SERBAHOKI</li>
              <li>Detail main dengan token</li>
              <li>Jika Pelanggan Sudah melakukan UNREG/Berhenti dari berlangganan maka Token nya akan Hangus/ Ter Reset ulang.</li>
              <li>Jika Pelanggan sudah melakukan UNREG kemudian REG kembali Token tidak terakumulasi.</li>

            </ul>
            <table class="table f-xs">
              <tr>
                <th class="text-right">TOKEN</th>
                <th class="text-center">GAME</th>
                <th class="text-center">DESKRIPSI</th>
              </tr>
              <tr>
                <td>1 TOKEN</td>
                <td>BALAP HOKI</td>
                <td>Dapat bermain 3 trek</td>
              </tr>
              <tr>
                <td>1 TOKEN</td>
                <td>BOLA HOKI</td>
                <td>Dapat 30x tendangan</td>
              </tr>
              <tr>
                <td>1 TOKEN</td>
                <td>ULAR TENGGO</td>
                <td>Dapat bermain 3 sesi</td>
              </tr>
              <tr>
                <td>1 TOKEN</td>
                <td>KETOKOO</td>
                <td>Dapat bermain 1 sesi</td>
              </tr>
            </table>
            <h6>Cara Mendapatkan Skor:</h6>
            <ul class="pl-3">
              <li>Jika pelanggan mengambil icon reward disetiap permainan maka akan mendapatakan 1 Skor reward</li>
              <li>Pelanggan bisa mendapatkan 10 skor tambahan dari sukses charging berlangganan (<big>1</big><sup><small>st</small></sup> charging berlangganan)</li>
              <li>Pelanggan mendapatkan skor 10 tambahan setiap dailypush (sukses renewal berlangganan)</li>
              <li>Skor reward akan diakumulasikan ke portal game.</li>
              <li>Pelanggan bisa mendapatkan 1x reward per satu periode dengan menukarkan reward nya dan start dari awal di produk tersebut.</li>
              <li>Reward langsung bisa di reedem , jika pelanggan mencapai skor yang ditentukan , makanya skor yang di reedeem langsung tidak akan terakumulasi karena sudah dipakai untuk reedem reward langsung</li>
              <li>Berkompetisi mendapatkan reward langsung jika pelanggan mencapai total skor yang di tentukan</li>
              <li>Berkompetisi menjadi peserta terbaik yang dengan tertinggi bisa mendapatkan reward</li>
              <li>Untuk reward langsung ada target skor yang harus dicapai.</li>
              <li>Jika Pelanggan Sudah melakukan UNREG/Berhenti dari berlangganan maka skor nya akan Hangus/ Ter Reset ulang.</li>
              <li>Jika Pelanggan sudah melakukan UNREG kemudian REG kembali skor tidak terakumulasi.</li>
            </ul>
          `,
        },
      ],
    };
  },
  // mounted() {
  //   console.log(this.store, this.coin);
  // },
  computed: {
    ...mapState({
      coin: "coin",
    }),
  },
  methods: {
    goTo(page) {
      this.$router.push({ name: page });
    },
  },
};
</script>

<style>
body {
  background-color: white;
}
</style>
<style scoped>
.actives {
  background-color: #fff !important;
  font-weight: 600;
}
.bg-burem {
  background-color: #eff0f6 !important;
}
.text-burem a {
  color: #a0a3bd !important;
}
.text-hitam a {
  color: #212121 !important;
}
.btn-custom {
    color: #212121;
    background-color: #fff;
    border: none;
    outline: none;
    text-align: left;
}
.btn-custom:focus, .btn-custom.focus, .btn-custom.not-collapsed {
    color: #D82827;
    border: none;
    box-shadow: none;
}
.btn-custom:not(:disabled):not(.disabled):active, .btn-custom:not(:disabled):not(.disabled).active, .show > .btn-custom.dropdown-toggle {
    color: #D82827;
    border: none;
    background-color: transparent;
}
.btn-custom:active, .btn-custom.active {
 background-color: red;
}
.card {
  border-radius: 12px;
}
.card-header, .card {
  border: none;
  background-color: #fff;
}
.card-body {
  background-color: #F6F6F6;
}
.accordion {
  background: #FFFFFF;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
/deep/ .table th {
    padding: 0.4rem;
    vertical-align: top;
    border: 1px solid #dee2e6;
}
/deep/ .table td {
    padding: 0.4rem;
    vertical-align: top;
    border: 1px solid #dee2e6;
}
/deep/ .f-xs {
  font-size: x-small;
}
</style>
