<template>
  <b-container fluid class="h-100 px-0" style="min-height:100vh;">
    <div class="content-login h-100" style="min-height:100vh;">
      <b-row
        cols-lg="3"
        cols-md="2"
        cols-sm="2"
        class="justify-content-center h-100 mx-0"
      >
        <b-col align-self="center">
          <b-img
            style="width: 40px;height: 40px; position: absolute; right: 1rem; top: 1rem;"
            alt="Title logo"
            :src="require('@/assets/icon-back-x.png')"
            @click="goTo('login')"
          ></b-img>
          <b-card class="card-login mt-2">
            <b-img
              style="height:13rem;"
              alt="Vue logo"
              :src="require('@/assets/login-logo.png')"
            ></b-img>
            <h4 class="txt-white mt-4">Verifikasi OTP</h4>
            <span class="txt-white"
              >6 digit kode OTP sudah dikirim ke nomor<br />
              {{ msisdn }}</span
            >
            <div id="recaptcha-container" style="display:none;"></div>
            <b-form @submit.prevent="verifyOtp" class="max-35 px-1">
              <b-form-input
                id="form-input-name"
                class="my-3 max-35 text-center rounded-pill form-control-lg"
                v-model="form.otp"
                required
              ></b-form-input>

              <b-button
                type="submit"
                block
                variant="primary"
                class="max-35 rounded-pill btn-lg"
                >Verifikasi</b-button
              >
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import firebase from "firebase";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      msisdn: this.$store.state.user,
      form: {
        otp: null
      },
      appVerifier: ""
    };
  },
  beforeMount() {
    this.initReCaptcha();
  },
  methods: {
    ...mapActions(["getProfile"]),
    handleSubmit() {
      this.getProfile();
    },
    goTo(page) {
      this.$router.push({ name: page });
    },
    setStore() {
      this.$store.commit("SET_TOKEN", this.token);
      this.$store.commit("SET_USER", this.msisdn);
    },
    sendOtp() {
      console.log("send otp");
      let phoneNumber = "+" + this.msisdn;
      let appVerifier = this.appVerifier;
      console.log(appVerifier, phoneNumber);
      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then(function(confirmationResult) {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          //
          console.log("SMS sent");
        })
        .catch(function(error) {
          // Error; SMS not sent
          // ...
          console.log(error, "Error ! SMS not sent");
        });
      // }
    },
    //
    verifyOtp() {
      // if(this.phNo.length != 10 || this.otp.length != 6){
      //   alert('Invalid Phone Number/OTP Format !');
      // }else{
      //
      let vm = this;
      let code = this.form.otp;
      //
      window.confirmationResult
        .confirm(code)
        .then(function(result) {
          // User signed in successfully.
          // var user = result.user;
          // ...
          console.log(result);
          //route to set password !
          // vm.$router.push({path:'/setPassword'})
          vm.handleSubmit();
        })
        .catch(function(error) {
          console.log(error);
          // User couldn't sign in (bad verification code?)
          // ...
        });
      // }
    },
    initReCaptcha() {
      console.log("init captcha");
      setTimeout(() => {
        // let vm = this
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: function(response) {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              // ...
              console.log(response, "callback");
            },
            "expired-callback": function() {
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
            }
          }
        );
        //
        this.appVerifier = window.recaptchaVerifier;
        this.sendOtp();
      }, 1000);
    }
  }
};
</script>

<style scoped>
.content-login {
  background: url("../assets/bg-layout.png") bottom no-repeat,
    linear-gradient(167.34deg, #ff4201 25.68%, #ff0077 65.68%);
  background-size: contain;
}
.card-login {
  background-color: transparent;
  border: none;
  transform: translate(0%, 9%);
  -webkit-transform: translate(0%, 9%);
}
.txt-white {
  color: white;
}
#form-input-otp {
  padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(
    to left,
    black 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px;
}
</style>
